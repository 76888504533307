/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue');

import { AlertPlugin, CollapsePlugin, ModalPlugin, NavbarPlugin, VBTooltipPlugin  } from "bootstrap-vue";
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import moment from 'moment';
require('moment-ferie-fr');
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
Vue.config.autoAddCss = false;
library.add(fas);

import _ from 'lodash';

// window._ = require('lodash');


// require('./bootstrap');


Vue.use(AlertPlugin);
Vue.use(CollapsePlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(VBTooltipPlugin);
window.moment = moment;
let locale = document.querySelector('html').lang;
moment.locale(locale);
window.__ = Vue.prototype.__ = str => {
    let str2 = _.get(window.i18n , str);
    return str2 ? str2 : str;
};
Vue.prototype._ = _;


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('base-datatable', require('./components/BaseDatatable').default);
Vue.component('client-order-datatable', require('./components/client/OrderDatatable').default);
Vue.component('client-invoice-datatable', require('./components/client/InvoiceDatatable').default);
Vue.component('order-form', require('./components/client/OrderForm').default);
Vue.component('quotation-form', require('./components/QuotationForm').default);

import GlobalMessage from "./components/GlobalMessage";

Vue.filter('localdate', value => {
    if (!value) return null;
    return moment(value, moment.ISO_8601).toDate().toLocaleDateString();
});


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    methods:{
        onConfirmation:function(message){
            var c = Vue.extend(GlobalMessage);
            var m = new c({
                propsData: {variant: 'success'}
            });
            m.$slots.default = [message];
            m.$mount();
            this.$refs.sticky.appendChild(m.$el);
        },
        onError:function(message){
            var c = Vue.extend(GlobalMessage);
            var m = new c({
                propsData: {variant: 'danger'}
            });
            m.$slots.default = [message];
            m.$mount();
            this.$refs.sticky.appendChild(m.$el);
        }
    }
});
