<template>
<div>
    <base-datatable
            @updated="onDatatableUpdated"
            ref="table"
            :filters="filters"
            :url="url"
            :can-edit="canEdit"
            :can-delete="canDelete"
            init-dir="desc"
            v-on:error="$root.onError"
            v-on:confirmation="$root.onConfirmation"
    >
        <template #filters>
            <select v-model="month" class="form-control mr-sm-3 mb-2 mb-sm-0">
                <option :value="null">{{ __('Month') }}</option>
                <option v-for="(m, i) in monthsList" :value="i+1">{{ m }}</option>
            </select>

            <select v-model="year" class="form-control mr-sm-3 mb-2 mb-sm-0">
                <option :value="null">{{ __('Year') }}</option>
                <option v-for="y in yearsList" :value="y">{{ y }}</option>
            </select>

            <select v-model.number="selectedStatus" class="form-control">
                <option :value="null">{{ __('All')}}</option>
                <option :value="-1">{{ __('To be confirmed') }}</option>
                <option v-for="(s, i) in status" :value="i">{{ s }}</option>
            </select>

            <select v-model.number="selectedContact" class="form-control ml-sm-3">
                <option :value="null">{{ __('All contacts')}}</option>
                <option v-for="contact in contacts" :value.number="contact.id">{{ contact.fullname }}</option>
            </select>
        </template>

        <template #col>
            <th>{{ __('Translation') }}</th>
            <th>{{ __('Source') }}</th>
            <th>{{ __('Invoice') }}</th>
        </template>

        <template #extra="slotProps">
            <td>
                <button class="btn btn-outline-primary" v-if="slotProps.item.can_download" @click="displayTranslations(slotProps.item)">{{ __('Download your translations') }}</button>
            </td>
            <td>
                <button class="btn btn-outline-primary" @click="displaySources(slotProps.item)">{{ __('Download your sources') }}</button>
            </td>
            <td><a :href="slotProps.item.invoice ? slotProps.item.invoice.url : null" v-if="slotProps.item.can_download" class="btn btn-link p-0" target="_blank"><font-awesome-icon size="lg" icon="file-pdf"></font-awesome-icon></a></td>
        </template>
    </base-datatable>

    <b-modal id="translations_modal" size="lg" :title="__('Translations download')" :ok-only="true" :ok-title="__('Close')">
        <template #default>
            <div class="row">
                <div class="col-sm-4">{{ __('Files') }}</div>
                <div class="col-sm-8">
                    <div v-for="file in selectedOrder.translation_files"><a :href="file.download_url">{{ file.name }}</a></div>
                </div>
            </div>

            <div v-if="selectedOrder.invoice">
                {{ __('Please download the corresponding invoice') }} : <a :href="selectedOrder.invoice.url" target="_blank">{{ selectedOrder.invoice.reference }}</a>
            </div>
        </template>
    </b-modal>

    <b-modal id="sources_modal" size="lg" :title="__('Source files download')" :ok-only="true" :ok-title="__('Close')">
        <template #default>
            <div class="row">
                <div class="col-sm-4">{{ __('Files') }}</div>
                <div class="col-sm-8">
                    <div v-for="file in selectedOrder.original_files"><a :href="file.download_url">{{ file.name }}</a></div>
                </div>
            </div>
        </template>
    </b-modal>

</div>
</template>

<script>

    export default {
        mounted() {
            this.monthsList = moment.months();
        },
        data(){
            return {
                month:null,
                year:null,
                monthsList:[],
                yearsList:[],
                selectedStatus:null,
                selectedOrder:null,
                selectedContact:null,
            }
        },
        props: {
            status:Object,
            contacts:Object,
            canDelete:{
                type:Boolean,
                default:false
            },
            canEdit:{
                type:Boolean,
                default:false
            },
            url:{
                type:String,
                required:true
            }
        },
        methods:{
            onDatatableUpdated(){
                let min = _.minBy(this.$refs.table.$data.originalItems, 'creation_date');
                let minYear = min.year;

                let now = moment();
                let year = now.year();
                for(let y = minYear; y <= year ; y++)
                    this.yearsList.push(y);
            },
            displayTranslations(order){
                this.selectedOrder = order;
                this.$bvModal.show('translations_modal');
            },
            displaySources(order){
                this.selectedOrder = order;
                this.$bvModal.show('sources_modal');
            },
        },
        computed:{
            filters:function(){
                let f = {
                    month:this.month,
                    year:this.year
                };
                if (this.selectedStatus !== null) f.status = this.selectedStatus;
                if (this.selectedContact !== null) f.contact_id = this.selectedContact;
                return f;
            }
        }
    }
</script>

<style scoped>

</style>