<template>
    <div>
        <LoadingOverlay :hidden="!sending"></LoadingOverlay>
        <form novalidate @submit="onValidate" :action="action">
            <fieldset>
                <legend>{{ __('Your name') }}</legend>

                <div class="form-group row">
                    <label for="contact_id" class="col-md-2 col-form-label text-left text-md-right">{{ __('Contact') }}</label>
                    <div class="col-md-4">
                        <select id="contact_id" name="contact_id" v-model="order.contact_id" class="form-control" :class="{ 'is-invalid' : errors.contact_id}">
                            <option v-for="contact in contacts" :value="contact.id">{{ contact.fullname}}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.contact_id" v-for="error in errors.contact_id">{{ error }}</div>
                    </div>

                    <label for="emails" class="col-md-2 col-form-label text-left text-md-right">{{ __('Delivery email(s)')}}&nbsp;*<br>({{ __('coma separated') }})</label>
                    <div class="col-md-4">
                        <input type="text" id="emails" name="emails" v-model="order.emails" class="form-control" :class="{ 'is-invalid' : errors.emails}">
                        <div class="invalid-feedback" v-if="errors.emails" v-for="error in errors.emails">{{ error }}</div>
                    </div>
                </div>

            </fieldset>




            <fieldset>
                <legend>{{ __('Your project') }}</legend>

                <div class="form-group row">
                    <label for="client_reference" class="col-md-2 col-form-label text-left text-md-right">{{ __('Project reference') }}&nbsp;*</label>
                    <div class="col-md-4">
                        <input type="text" id="client_reference" name="client_reference" v-model="order.client_reference" class="form-control" :class="{ 'is-invalid' : errors.client_reference}" @input="onClientReference">
                        <span class="text-info text-nowrap font-italic" v-if="refExists"><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> {{ __('Reference exists in our database') }}</span>
                        <div class="invalid-feedback" v-if="errors.client_reference" v-for="error in errors.client_reference">{{ error }}</div>
                    </div>

                    <label for="deadline" class="col-md-2 col-form-label text-left text-md-right">{{ __('Deadline') }}&nbsp;*</label>
                    <div class="col-md-4">
                        <v-date-picker v-model="order.deadline" mode="single" :disabled-dates="calendarDisabledDates" :input-props="{class:deadlineClass, id:'deadline', autocomplete:'off'}"></v-date-picker>
                        <!--                        <input type="text" id="deadline" name="deadline" v-model="order.deadline" class="form-control" :class="{ 'is-invalid' : errors.deadline}">-->
                        <div class="invalid-feedback d-block" v-if="errors.deadline" v-for="error in errors.deadline">{{ error }}</div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="document" class="col-md-2 col-form-label text-left text-md-right">{{ __('Document') }}&nbsp;*</label>
                    <div class="col-md-4">
                        <select id="document" name="document" v-model.number="order.document" class="form-control" @change="onChangeDocument" :class="{ 'is-invalid' : errors.document}">
                            <option v-for="(document, i) in documents" :value="i">{{ document }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.document" v-for="error in errors.document">{{ error }}</div>
                    </div>

                    <template v-if="!isPatent && order.document !== null">
                    <label for="specify" class="col-md-2 col-form-label text-left text-md-right">{{ __('Specify') }}&nbsp;*</label>
                    <div class="col-md-4">
                        <input type="text" id="specify" name="specify" v-model="order.specify" class="form-control" :class="{ 'is-invalid' : errors.specify}" :placeholder="specifyPlaceholder">
                        <div class="invalid-feedback" v-if="errors.specify" v-for="error in errors.specify">{{ error }}</div>
                    </div>
                    </template>
                </div>


                <b-collapse :visible="isPatent">
                    <div class="form-group row">
                        <label for="ep_number" class="col-md-2 col-form-label text-left text-md-right">{{ __('EP Number') }}</label>
                        <div class="col-md-4">
                            <input type="text" id="ep_number" name="number_pages" v-model="order.ep_number" class="form-control" :class="{ 'is-invalid' : errors.ep_number}">
                            <div class="invalid-feedback" v-if="errors.ep_number" v-for="error in errors.ep_number">{{ error }}</div>
                        </div>

                        <label for="application_number" class="col-md-2 col-form-label text-left text-md-right">{{ __('Application number') }}</label>
                        <div class="col-md-4">
                            <input type="text" id="application_number" name="application_number" v-model="order.application_number" class="form-control" :class="{ 'is-invalid' : errors.application_number}">
                            <div class="invalid-feedback" v-if="errors.application_number" v-for="error in errors.application_number">{{ error }}</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="field_id" class="col-md-2 col-form-label text-left text-md-right">{{ __('Field') }}&nbsp;*</label>
                        <div class="col-md-4">
                            <select id="field_id" name="field_id" v-model="order.field_id" class="form-control" :class="{ 'is-invalid' : errors.field_id}">
                                <option v-for="field in fields" :value="field.id">{{ field.name}}</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.field_id" v-for="error in errors.field_id">{{ error }}</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Patent') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="patent_bool" name="patent_bool" v-model="order.patent_bool" @change="onPatent">
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Claims') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="claims_bool" name="claims_bool" v-model="order.claims_bool">
                            </div>
                        </div>

                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Title') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="title_bool" name="title_bool" v-model="order.title_bool">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <b-collapse :visible="order.claims_bool" class="col-md-6" @hidden="claimsHidden = true" @show="claimsHidden = false">
                            <div class="form-group row">
                                <label for="number_claims" class="col-md-4 col-form-label text-left text-md-right">{{ __('Number of claims') }}</label>
                                <div class="col-md-8">
                                    <input type="text" id="number_claims" name="number_pages" v-model="order.number_claims" class="form-control" :class="{ 'is-invalid' : errors.number_claims}">
                                    <div class="invalid-feedback" v-if="errors.number_claims" v-for="error in errors.number_claims">{{ error }}</div>
                                </div>
                            </div>
                        </b-collapse>

                        <b-collapse :visible="order.title_bool" class="col-md-6" :class="{'offset-md-6':claimsHidden}">
                            <div class="form-group row">
                                <label for="title" class="col-md-4 col-form-label text-left text-md-right">{{ __('Title') }}</label>
                                <div class="col-md-8">
                                    <input type="text" id="title" name="title" v-model="order.title" class="form-control" :class="{ 'is-invalid' : errors.title}">
                                    <div class="invalid-feedback" v-if="errors.title" v-for="error in errors.title">{{ error }}</div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Description') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="description_bool" name="description_bool" v-model="order.description_bool">
                                <small class="text-danger" v-if="order.description_bool && !order.patent_bool">{{ __('Please remember to attach the translated claims') }}</small>
                            </div>
                        </div>

                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Proofreading') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="proofreading_bool" name="proofreading_bool" v-model="order.proofreading_bool">
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Abstract') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="abstract_bool" name="abstract_bool" v-model="order.abstract_bool">
                            </div>
                        </div>

                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Division') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="division_bool" name="division_bool" v-model="order.division_bool">
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Figures') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="figures_bool" name="figures_bool" v-model="order.figures_bool">
                            </div>
                        </div>

                        <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Certification') }}</label>
                        <div class="col-md-4 col-form-label">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="certification_bool" name="certification_bool" v-model="order.certification_bool">
                                <small class="text-danger" v-if="order.certification_bool">{{ __('Please remember to attach the pre-filled certification') }}</small>
                            </div>
                        </div>


                    </div>

                    <div class="row">
                        <b-collapse :visible="order.figures_bool" class="col-md-6" @hidden="figuresHidden = true" @show="figuresHidden = false">
                            <div class="form-group row">
                                <label for="number_figures" class="col-md-4 col-form-label text-left text-md-right">{{ __('Number of figures') }}</label>
                                <div class="col-md-8">
                                    <input type="text" id="number_figures" name="number_figures" v-model="order.number_figures" class="form-control" :class="{ 'is-invalid' : errors.number_figures}">
                                    <div class="invalid-feedback" v-if="errors.number_figures" v-for="error in errors.number_figures">{{ error }}</div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>

                </b-collapse>



                <div class="form-group row">
                    <label for="number_pages" class="col-md-2 col-form-label text-left text-md-right">{{ __('Number of pages to be translated') }}</label>
                    <div class="col-md-4">
                        <input type="text" id="number_pages" name="number_pages" v-model="order.number_pages" class="form-control" :class="{ 'is-invalid' : errors.number_pages}">
                        <div class="invalid-feedback" v-if="errors.number_pages" v-for="error in errors.number_pages">{{ error }}</div>
                    </div>

                    <label for="source_words" class="col-md-2 col-form-label text-left text-md-right">{{ __('Number of source words') }}</label>
                    <div class="col-md-4">
                        <input type="text" id="source_words" name="source_words" v-model="order.source_words" class="form-control" :class="{ 'is-invalid' : errors.source_words}">
                        <div class="invalid-feedback" v-if="errors.source_words" v-for="error in errors.source_words">{{ error }}</div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="language_id" class="col-md-2 col-form-label text-left text-md-right">{{ __('Source language') }}&nbsp;*</label>
                    <div class="col-md-4">
                        <select id="language_id" name="language_id" v-model.number="order.language_id" class="form-control" :class="{ 'is-invalid' : errors.language_id}">
                            <option v-for="language in languages" :value="language.id">{{ language.name }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.language_id" v-for="error in errors.language_id">{{ error }}</div>
                    </div>

                    <label for="target_languages" class="col-md-2 col-form-label text-left text-md-right">{{ __('Target language(s)') }}&nbsp;*</label>
                    <div class="col-md-4">
                        <v-select multiple
                                  id="target_languages"
                                  name="target_languages"
                                  :reduce="l => l.id"
                                  :options="languages"
                                  v-model="order.target_languages"
                                  label="name"
                                  :class="{'is-invalid' : errors.target_languages}"
                        ></v-select>
                        <!--<select id="target_languages" name="target_languages" v-model="order.target_languages" class="form-control" :class="{ 'is-invalid' : errors.target_languages}">-->
                            <!--<option v-for="language in languages" :value="language.id">{{ language.name }}</option>-->
                        <!--</select>-->
                        <div class="invalid-feedback" v-if="errors.target_languages" v-for="error in errors.target_languages">{{ error }}</div>
                    </div>
                </div>

            </fieldset>



            <fieldset>
                <legend>{{ __('Your documents') }}</legend>
                <div class="row">
                    <div class="col-sm-6">
                        <document-item name="original" label="Document to be translated" :descPlaceholder="__('Translated claims, parent document, ...')" :required="true" :errors="errors" :upload-url="uploadUrl" v-model="order.original_files" :type.number="1" @error="onError" @deletedOriginalFile="onDeletedOriginalFile"></document-item>
                    </div>

                </div>

            </fieldset>




            <fieldset>
                <legend>{{ __('Your comments') }}</legend>
                <div class="form-group row">
                    <label for="comments" class="col-md-2 col-form-label text-left text-md-right">{{ __('Comments') }}</label>
                    <div class="col-md-10">
                        <textarea id="comments" name="comments" v-model="order.comments" class="form-control" rows="3"></textarea>
                    </div>
                </div>
            </fieldset>





            <div class="form-group text-right">
                <button type="submit" class="btn btn-lg btn-success">{{ __('Save') }}</button>
            </div>
        </form>


        <b-modal id="summary-modal" :title="__('Order acknowledgment')" :ok-title="__('Confirm')" :cancel-title="__('Cancel')" size="xl" @ok="onSubmit">
            <div class="row">
                <div class="col-md-3 text-right">{{ __('Project reference') }}</div>
                <div class="col-md-3">{{ order.client_reference }}
                    <template v-if="refExists">
                    <br>
                    <span class="text-info text-nowrap font-italic"><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> {{ __('Reference exists in our database') }}</span>
                    </template>
                </div>
                <div class="col-md-3 text-right">{{ __('Deadline') }}</div>
                <div class="col-md-3">{{ order.deadline | localdate }}</div>
            </div>
            <div class="row" v-if="isPatent && (order.application_number || order.ep_number)">
                <template v-if="order.ep_number">
                    <div class="col-md-3 text-right">{{ __('EP Number') }}</div>
                    <div class="col-md-3">{{ order.ep_number }}</div>
                </template>
                <template v-if="order.application_number">
                    <div class="col-md-3 text-right">{{ __('Application number') }}</div>
                    <div class="col-md-3">{{ order.application_number }}</div>
                </template>
            </div>
            <div class="row">
                <div class="col-md-3 text-right">{{ __('Source language') }}</div>
                <div class="col-md-3">{{ sourceLanguage }}</div>
                <div class="col-md-3 text-right">{{ __('Target language(s)') }}</div>
                <div class="col-md-3">{{ targetLanguages }}</div>
            </div>
            <div class="row">
                <div class="col-md-3 text-right">{{ __('Document') }}</div>
                <div class="col-md-3">{{ documents[order.document] }}</div>
                <template v-if="!isPatent && order.document !== null">
                <div class="col-md-3 text-right">{{ __('Specify') }}</div>
                <div class="col-md-3">{{ order.specify }}</div>
                </template>
            </div>
            <div class="row">
                <div class="col-md-3 text-right">{{ __('Document') }}</div>
                <div class="col-md-3">
                    <div v-if="order.claims_bool">{{ __('Claims') }}</div>
                    <div v-if="order.description_bool">{{ __('Description') }}</div>
                    <div v-if="order.title_bool">{{ __('Title') }}</div>
                    <div v-if="order.figures_bool">{{ __('Figures') }}</div>
                </div>
                <div class="col-md-3 text-right">{{ __('Number of pages to be translated') }}</div>
                <div class="col-md-3">{{ order.number_pages }}</div>
            </div>
            <div class="row mt-5">
                <div class="col-md-3 text-right">{{ __('Order acknowledgment') }}</div>
                <div class="col-md-9" v-html="settings.client_order_acknowledgment"></div>
            </div>

        </b-modal>


        <b-modal id="confirmation-modal" :title="confirmationTitle + ' : ' + order.client_reference" size="xl" ok-only :ok-title="__('Close')" @hidden="onHiddenConfirm">
            <div class="row">
                <div class="col-md-3 text-right">{{ confirmTitle }}</div>
                <div class="col-md-9" v-html="confirmText"></div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import VCalendar from 'v-calendar';
    // import 'v-calendar/lib/v-calendar.min.css';
    import DocumentItem from "../DocumentItem";
    import vSelect from 'vue-select';
    import LoadingOverlay from "../LoadingOverlay";

    export default {
        components: {LoadingOverlay, DocumentItem, vSelect},
        mounted() {
            let names = [
                'client_order_acknowledgment',
                'client_order_confirmation',
                'client_urgency_acknowledgment',
            ];

            let a1 = axios(this.settingsUrl + '?names[]=' + names.join('&names[]=')).then( ({data}) => {
                this.settings = data.settings;
                this.languages = data.languages;
                this.fields = data.fields;
                this.unavailabilities = data.unavailabilities;
            });

            let a4 = axios(this.contactsUrl).then( ({data}) => {
                this.contacts = data;
            });

        },
        computed:{
            specifyPlaceholder(){
                let placeholder = null;
                if (this.order.document === 2)
                    placeholder = __('Pleadings, Power of Attorney, Deed of assignment, Agreement, Sworn translation...');
                else if (this.order.document === 3)
                    placeholder = __('Brochure, Press release, Marketing Authorization, Financial document, Technical document...');
                return placeholder;
            },
            isPatent(){
                return this.order.document === 1;
            },
            deadlineClass(){
                return {
                    'form-control':true,
                    'is-invalid':this.errors.deadline
                };
            },
            calendarDisabledDates(){
                let ary = [
                    {
                        start:null,
                        end:new Date()
                    },
                    {
                        weekdays:[1, 7]
                    }
                ];

                for(let i = 0 ; i < this.unavailabilities.length ; i++){
                    ary.push({
                        start:this.unavailabilities[i].start_at,
                        end:this.unavailabilities[i].end_at
                    });
                }

                return ary;
            },
            sourceLanguage(){
                let l = this.languages.find(item => item.id === this.order.language_id);
                return l ? l.name : null;
            },
            targetLanguages(){
                let l = this.languages.filter(item => this.order.target_languages.includes(item.id));
                return l ? l.reduce((carry, item) => {
                        return carry ? carry + ', ' + item.name : item.name;
                    }, '') : null;
            },
            dataToSend(){
                let data = Object.assign({deletedFiles:this.deletedFiles}, this.order);
                data.deadline = moment(data.deadline).utc(true);
                return data;
            },
            isUrgent(){
                let deadline = moment(this.order.deadline).hour(0).minutes(0).seconds(0);
                let currentDate = moment().hour(0).minutes(0).seconds(0);
                let count = 0;
                let unavailable;

                while(currentDate.isBefore(deadline)){
                    currentDate.add(1, 'days');
                    unavailable = this.unavailabilities.findIndex(item => {
                        return currentDate.isSameOrAfter(moment(item.start_at).utc(true)) && currentDate.isSameOrBefore(moment(item.end_at).utc(true));
                    });

                    if (![6, 7].includes(deadline.isoWeekday()) && !deadline.isFerie() && unavailable === -1)
                        count++;
                }
                return count <= 2;
            },
            confirmTitle(){
                return this.isUrgent ? __('Acknowledgment urgency') : this.confirmationTitle;
            },
            confirmText(){
                return this.isUrgent ? this.settings.client_urgency_acknowledgment : this.settings.client_order_confirmation;
            }
        },
        data() {
            return {
                errors:{},
                submit_action:this.action,
                submit_method:this.method,
                settings:{},
                languages:[],
                fields:[],
                contacts:[],
                unavailabilities:[],
                order:{
                    client_reference:null,
                    contact_id:null,
                    emails:null,
                    document:null,
                    specify:null,
                    number_pages:null,
                    deadline:null,
                    ep_number:null,
                    application_number:null,
                    field_id:null,
                    claims_bool:null,
                    number_claims:null,
                    title_bool:null,
                    title:null,
                    description_bool:null,
                    patent_bool:null,
                    abstract_bool:null,
                    proofreading_bool:null,
                    figures_bool:null,
                    number_figures:null,
                    division_bool:null,
                    certification_bool:null,
                    language_id:null,
                    target_languages:[],
                    comments:null,
                    original_files:[],
                },
                deletedFiles:[],
                claimsHidden:false,
                figuresHidden:true,
                refExists:false,
                refTimer:false,
                sending:false,
            }
        },
        methods:{
            onError(message){
                this.$emit('error', message);
            },
            onConfirmation(message){
                this.$emit('confirmation', message);
            },
            onDeletedOriginalFile(file){
                this.order.original_files = this.order.original_files.filter(item => item !== file);
                this.deletedFiles.push(file.id);
            },
            onClientReference(){
                if (this.refTimer) {
                    clearTimeout(this.refTimer);
                    this.refTimer = null;
                }
                if (!this.order.client_reference){
                    this.refExists = false;
                }
                else{
                    this.refTimer = setTimeout(this.checkClientReference, this.timerInterval);
                }
            },
            checkClientReference(){
                axios.post(this.checkClientReferenceUrl,
                    {
                        order_id:this.order.id,
                        client_reference:this.order.client_reference,
                    }
                ).then( ({ data }) => {
                    this.refExists = !!data.refExists;
                }).catch( error => {
                    this.refExists = false;
                    if (error.response.data){
                        if (error.response.data.message)
                            this.$emit('error', error.response.data.message);
                        else
                            this.$emit('error', error.response.data);
                    }
                });
            },
            onPatent(){
                if (this.order.patent_bool){
                    this.order.claims_bool = true;
                    this.order.title_bool = true;
                    this.order.description_bool = true;
                    this.order.abstract_bool = true;
                    this.order.figures_bool = true;
                }
            },
            onChangeDocument(){
                // if (this.order.document === 1){
                //     this.order.claims_bool = true;
                //     this.order.description_bool = true;
                //     this.order.abstract_bool = true;
                // }
            },
            onValidate(e){
                if (this.showSummary){
                    e.preventDefault();
                    axios.post(this.validateFormUrl, this.dataToSend).then( ({data}) => {
                        this.errors = {};
                        this.$bvModal.show('summary-modal');
                        this.refExists = data.refExists;

                    }).catch( error => {
                        if (error.response && error.response.data){
                            if (error.response.data.errors){
                                this.errors = error.response.data.errors;
                            }
                            if (error.response.data.message)
                                this.$emit('error', error.response.data.message);
                        }

                    });
                }
                else
                    this.onSubmit(e);

            },
            onSubmit(e) {
                e.preventDefault();
                if (this.sending) return;

                this.sending = true;
                axios({
                    method:this.submit_method,
                    url:this.submit_action,
                    data: this.dataToSend
                }).then( ({data}) => {
                    this.errors = {};
                    if (this.showSummary) this.$bvModal.hide('summary-modal');
                    this.$bvModal.show('confirmation-modal');

                    // this.$emit('confirmation', data.message);

                }).catch( error => {
                    if (error.response && error.response.data){
                        if (error.response.data.errors){
                            this.errors = error.response.data.errors;
                        }
                        if (error.response.data.message)
                            this.$emit('error', error.response.data.message);
                    }

                }).finally(() => {
                    this.sending = false;
                });
            },
            onHiddenConfirm(e){
                if (this.redirectUrl)
                    window.location = this.redirectUrl;
            }
        },
        props:{
            method:String,
            url:String,
            checkClientReferenceUrl:String,
            settingsUrl:String,
            contactsUrl:String,
            uploadUrl:String,
            validateFormUrl:String,
            action:String,
            id:Number,
            status:Object,
            documents:Object,
            providerStatus:Object,
            confirmationTitle:String,
            redirectUrl:String,
            showSummary:{
                type:Boolean,
                default:true,
            },
            timerInterval:{
                type:Number,
                default:300
            }
        },
        watch:{
            'order.contact_id'(newVal, oldVal){
                let contact = this.contacts.find(item => item.id === newVal);
                this.order.emails = contact ? contact.email : null;
            }
        }
    }
</script>

<style scoped>

</style>
