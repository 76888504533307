<template>
    <div>
        <form novalidate @submit="onsubmit" :action="action">
            <fieldset>
                <legend>{{ __('admin_localization.labels.quote') }}</legend>

                <div class="form-group row">
                    <label for="reference" class="col-md-2 col-form-label text-left text-md-right">{{ __('admin_localization.labels.our_reference') }}</label>
                    <div class="col-md-4">
                        <input type="text" id="reference" name="reference" v-model="quotation.reference" class="form-control" readonly>
                    </div>

                    <label for="client_reference" class="col-md-2 col-form-label text-left text-md-right">{{ __('admin_localization.labels.client_reference') }}</label>
                    <div class="col-md-4">
                        <input type="text" id="client_reference" name="client_reference" v-model="quotation.client_reference" class="form-control" :class="{ 'is-invalid' : errors.client_reference}" @input="onClientReference">
                        <span class="text-info text-nowrap font-italic" v-if="refExists"><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> {{ __('Reference exists in our database') }}</span>
                        <div class="invalid-feedback" v-if="errors.client_reference" v-for="error in errors.client_reference">{{ error }}</div>
                    </div>
                </div>



            </fieldset>



            <fieldset>
                <legend>{{ __('Client') }}</legend>


                <div class="form-group row">
                    <label for="client_id" class="col-md-2 col-form-label text-left text-md-right">{{ __('admin_localization.labels.client') }}</label>
                    <div class="col-md-4">
                        <select id="client_id" name="client_id" v-model="quotation.client_id" class="form-control" :class="{ 'is-invalid' : errors.client_id}">
                            <option v-for="client in clients" :value="client.id">{{ client.name}}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.client_id" v-for="error in errors.client_id">{{ error }}</div>
                    </div>

                    <label for="contact_id" class="col-md-2 col-form-label text-left text-md-right">{{ __('admin_localization.labels.contact') }}</label>
                    <div class="col-md-4">
                        <select id="contact_id" name="contact_id" v-model="quotation.contact_id" class="form-control" :class="{ 'is-invalid' : errors.contact_id}">
                            <option v-for="contact in contacts" :value="contact.id">{{ contact.fullname}}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.contact_id" v-for="error in errors.contact_id">{{ error }}</div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="emails" class="col-md-2 col-form-label text-left text-md-right">{{ __('admin_localization.labels.delivery_emails')}} *<br>({{ __('admin_localization.labels.coma_separated') }})</label>
                    <div class="col-md-10">
                        <input type="text" id="emails" name="emails" v-model="quotation.emails" class="form-control" :class="{ 'is-invalid' : errors.emails}">
                        <div class="invalid-feedback" v-if="errors.emails" v-for="error in errors.emails">{{ error }}</div>
                    </div>
                </div>

            </fieldset>



            <fieldset>
                <legend>{{ __('admin_localization.labels.project') }}</legend>

                <div class="form-group row">
                    <label for="document" class="col-md-2 col-form-label text-left text-md-right">{{ __('Document') }} *</label>
                    <div class="col-md-4">
                        <select id="document" name="document" v-model.number="quotation.document" class="form-control" @change="onChangeDocument" :class="{ 'is-invalid' : errors.document}">
                            <option v-for="(document, i) in __('common.documents')" :value="i">{{ document }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.document" v-for="error in errors.document">{{ error }}</div>
                    </div>

                    <template v-if="!isPatent && quotation.document !== null">
                    <label for="specify" class="col-md-2 col-form-label text-left text-md-right">{{ __('Specify') }} *</label>
                    <div class="col-md-4">
                        <input type="text" id="specify" name="specify" v-model="quotation.specify" class="form-control" :class="{ 'is-invalid' : errors.specify}" :placeholder="specifyPlaceholder">
                        <div class="invalid-feedback" v-if="errors.specify" v-for="error in errors.specify">{{ error }}</div>
                    </div>
                    </template>
                </div>


                <b-collapse :visible="isPatent">
                <div class="form-group row">
                    <label for="ep_number" class="col-md-2 col-form-label text-left text-md-right">{{ __('EP Number') }}</label>
                    <div class="col-md-4">
                        <input type="text" id="ep_number" name="number_pages" v-model="quotation.ep_number" class="form-control" :class="{ 'is-invalid' : errors.ep_number}">
                        <div class="invalid-feedback" v-if="errors.ep_number" v-for="error in errors.ep_number">{{ error }}</div>
                    </div>

                    <label for="application_number" class="col-md-2 col-form-label text-left text-md-right">{{ __('Application number') }}</label>
                    <div class="col-md-4">
                        <input type="text" id="application_number" name="application_number" v-model="quotation.application_number" class="form-control" :class="{ 'is-invalid' : errors.application_number}">
                        <div class="invalid-feedback" v-if="errors.application_number" v-for="error in errors.application_number">{{ error }}</div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="field_id" class="col-md-2 col-form-label text-left text-md-right">{{ __('Field') }} *</label>
                    <div class="col-md-4">
                        <select id="field_id" name="field_id" v-model="quotation.field_id" class="form-control" :class="{ 'is-invalid' : errors.field_id}">
                            <option v-for="field in fields" :value="field.id">{{ field.name}}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.field_id" v-for="error in errors.field_id">{{ error }}</div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Patent') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="patent_bool" name="patent_bool" v-model="quotation.patent_bool" @change="onPatent">
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Claims') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="claims_bool" name="claims_bool" v-model="quotation.claims_bool">
                        </div>
                    </div>

                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Title') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="title_bool" name="title_bool" v-model="quotation.title_bool">
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="number_claims" class="col-md-2 col-form-label text-left text-md-right">{{ __('Number of claims') }}</label>
                    <div class="col-md-4">
                        <input type="text" id="number_claims" name="number_pages" v-model="quotation.number_claims" class="form-control" :class="{ 'is-invalid' : errors.number_claims}">
                        <div class="invalid-feedback" v-if="errors.number_claims" v-for="error in errors.number_claims">{{ error }}</div>
                    </div>

                    <label for="title" class="col-md-2 col-form-label text-left text-md-right">{{ __('Title') }}</label>
                    <div class="col-md-4">
                        <input type="text" id="title" name="title" v-model="quotation.title" class="form-control" :class="{ 'is-invalid' : errors.title}">
                        <div class="invalid-feedback" v-if="errors.title" v-for="error in errors.title">{{ error }}</div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Description') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="description_bool" name="description_bool" v-model="quotation.description_bool">
                        </div>
                    </div>

                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Proofreading') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="proofreading_bool" name="proofreading_bool" v-model="quotation.proofreading_bool">
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Abstract') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="abstract_bool" name="abstract_bool" v-model="quotation.abstract_bool">
                        </div>
                    </div>

                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Division') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="division_bool" name="division_bool" v-model="quotation.division_bool">
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Figures') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="figures_bool" name="figures_bool" v-model="quotation.figures_bool">
                        </div>
                    </div>

                    <label class="col-md-2 col-form-label text-left text-md-right">{{ __('Certification') }}</label>
                    <div class="col-md-4 col-form-label">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="certification_bool" name="certification_bool" v-model="quotation.certification_bool">
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="number_figures" class="col-md-2 col-form-label text-left text-md-right">{{ __('Number of figures') }} <!--<span v-show="quotation.figures_bool">*</span>--></label>
                    <div class="col-md-4">
                        <input type="text" id="number_figures" name="number_figures" v-model="quotation.number_figures" class="form-control" :class="{ 'is-invalid' : errors.number_figures}">
                        <div class="invalid-feedback" v-if="errors.number_figures" v-for="error in errors.number_figures">{{ error }}</div>
                    </div>


                </div>
                </b-collapse>


                <div class="form-group row">
                    <label for="number_pages" class="col-md-2 col-form-label text-left text-md-right">{{ __('Number of pages') }} *</label>
                    <div class="col-md-4">
                        <input type="text" id="number_pages" name="number_pages" v-model="quotation.number_pages" class="form-control" :class="{ 'is-invalid' : errors.number_pages}">
                        <div class="invalid-feedback" v-if="errors.number_pages" v-for="error in errors.number_pages">{{ error }}</div>
                    </div>

                    <label for="deadline" class="col-md-2 col-form-label text-left text-md-right">{{ __('Deadline') }} *</label>
                    <div class="col-md-4">
                        <v-date-picker v-model="quotation.deadline" mode="single" :disabled-dates="calendarDisabledDates" :input-props="{class:deadlineClass, id:'deadline', autocomplete:'off'}"></v-date-picker>
                        <!--                        <input type="text" id="deadline" name="deadline" v-model="quotation.deadline" class="form-control" :class="{ 'is-invalid' : errors.deadline}">-->
                        <div class="invalid-feedback d-block" v-if="errors.deadline" v-for="error in errors.deadline">{{ error }}</div>
                    </div>
                </div>

            </fieldset>


            <fieldset>
                <legend>{{ __('Documents') }}</legend>
                <div class="row">
                    <div class="col-sm-6">
                        <document-item name="original" label="Original" :upload-url="uploadUrl" v-model="quotation.original_files" :type="1" @error="onError" @deletedOriginalFile="onDeletedOriginalFile"></document-item>
                    </div>
                </div>

            </fieldset>



            <fieldset>
                <legend>{{ __('admin_localization.labels.languages_pair') }}</legend>
                <div class="form-group row">
                    <label for="language_id" class="col-md-2 col-form-label text-left text-md-right">{{ __('Source language') }} *</label>
                    <div class="col-md-4">
                        <select id="language_id" name="language_id" v-model.number="quotation.language_id" class="form-control" :class="{ 'is-invalid' : errors.language_id}" >
                            <option v-for="language in languages" :value="language.id">{{ language.name }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.language_id" v-for="error in errors.language_id">{{ error }}</div>
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-3">{{ __('Target language') }} *</div>
                </div>

                <div class="form-group row" v-for="(l, index) in quotation.languages">
                    <div class="col-3">
                        <select v-model="quotation.languages[index]" class="form-control"  :class="{ 'is-invalid' : errors['languages.'+index]}">
                            <option :value="null"></option>
                            <option v-for="language in languages" :value="language.id">{{ language.name }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors['languages.'+index]" v-for="error in errors['languages.'+index]">{{ error }}</div>
                    </div>

                    <div class="col-1" v-if="quotation.languages.length > 1 || index > 0">
                        <button type="button" class="btn btn-link" @click="removeLanguage(index)"><font-awesome-icon icon="trash-alt"></font-awesome-icon></button>
                    </div>
                </div>


                <div class="text-right">
                    <button type="button" class="btn btn-primary" @click="addLanguage">{{ __('Add language') }}</button>
                </div>


            </fieldset>


            <fieldset>
                <legend>{{ __('admin_localization.labels.pricing') }}</legend>
                <div class="form-group row">
                    <label for="price" class="col-md-2 col-form-label text-left text-md-right">{{ __('admin_localization.labels.quotation_price') }}</label>
                    <div class="col-md-4 input-group">
                        <input type="text" id="price" v-model="quotation.price" class="form-control">
                        <div class="input-group-append">
                            <span class="input-group-text">{{ currency }}</span>
                        </div>
                    </div>
                </div>

            </fieldset>

            <fieldset>
                <legend>{{ __('Comments') }}</legend>
                <div class="form-group row">
                    <label for="comments" class="col-md-2 col-form-label text-left text-md-right">{{ __('admin_localization.labels.comments_to_the_client') }}</label>
                    <div class="col-md-10">
                        <textarea id="comments" name="comments" v-model="quotation.comments" class="form-control" rows="3"></textarea>
                    </div>
                </div>
            </fieldset>


            <div class="form-group text-right">
                <button type="button" v-if="quotation.id" @click="onPreview" class="btn btn-lg btn-primary mr-5">{{ __('admin_localization.quotations.preview') }}</button>
                <button type="button" @click="SaveAndSend" class="btn btn-lg btn-success mr-3">{{ __('admin_localization.quotations.generate_and_send_quote') }}</button>
                <button type="submit" class="btn btn-lg btn-success">{{ __('Save') }}</button>
            </div>
        </form>


    </div>
</template>

<script>
    import axios from 'axios';
    import VCalendar from 'v-calendar';
    // import 'v-calendar/lib/v-calendar.min.css';
    import DocumentItem from "./DocumentItem";


    export default {
        components: {DocumentItem},
        mounted() {

            // let a1 = axios(this.languagesUrl).then( ({data}) => {
            //     this.languages = data;
            // });

            let url = this.settingsUrl;
            if (this.id)
                url += '?quotation_id=' + this.id;
            let a1 = axios(url).then( ({data}) => {
                this.languages = data.languages;
                this.unavailabilities = data.unavailabilities;
            });

            let a3 = axios(this.fieldsUrl).then( ({data}) => {
                this.fields = data;
            });

            let a4 = axios(this.clientsUrl).then( ({data}) => {
                this.clients = data;
            });

            Promise.all([a1, a3, a4]).then(() => {
                if (this.url){
                    axios(this.url).then( ({data}) => {
                        data.deadline = new Date(data.deadline);
                        this.quotation = data;
                        this.fields = this.fields.filter(item => this.quotation.field_id === item.id || !item.deleted_at);
                        this.languages = this.languages.filter(item => this.quotation.language_id === item.id || this.quotation.languages.includes(item.id) || !item.deleted_at);
                    });
                }
                else{
                    this.fields = this.fields.filter(item => !item.deleted_at);
                    this.languages = this.languages.filter(item => !item.deleted_at);
                }


            });

        },
        computed:{
            isPatent:function(){
                return this.quotation.document === 1;
            },
            deadlineClass:function(){
                return {
                    'form-control':true,
                    'is-invalid':this.errors.deadline
                };
            },
            calendarDisabledDates:function(){
                let ary = [
                    {
                        start:null,
                        end:this.quotation.created_at ? new Date(this.quotation.created_at) : new Date()
                    },
                    {
                        weekdays:[1, 7]
                    }
                ];

                for(let i = 0 ; i < this.unavailabilities.length ; i++){
                    ary.push({
                        start:this.unavailabilities[i].start_at,
                        end:this.unavailabilities[i].end_at
                    });
                }

                return ary;
            },
            specifyPlaceholder:function(){
                let placeholder = null;
                if (this.quotation.document === 2)
                    placeholder = __('Pleadings, Power of Attorney, Deed of assignment, Agreement, Sworn translation...');
                else if (this.quotation.document === 3)
                    placeholder = __('Brochure, Press release, Marketing Authorization, Financial document, Technical document...');
                return placeholder;
            },
            currency:function(){
                return this.selectedClient ? __('common.currencies')[this.selectedClient.currency] : null;
            }
        },
        data() {
            return {
                errors:{},
                refExists:false,
                refTimer:null,
                submit_action:this.action,
                submit_method:this.method,
                languages:[],
                fields:[],
                clients:[],
                contacts:[],
                unavailabilities:[],
                quotation:{
                    reference:null,
                    client_reference:null,
                    client_id:null,
                    contact_id:null,
                    emails:null,
                    document:null,
                    specify:null,
                    number_pages:null,
                    deadline:null,
                    ep_number:null,
                    application_number:null,
                    field_id:null,
                    claims_bool:null,
                    number_claims:null,
                    title_bool:null,
                    title:null,
                    description_bool:null,
                    patent_bool:null,
                    abstract_bool:null,
                    proofreading_bool:null,
                    figures_bool:null,
                    number_figures:null,
                    division_bool:null,
                    certification_bool:null,
                    language_id:null,
                    comments:null,
                    languages:[null],
                    original_files:[],
                },
                selectedClient:null,
                sendQuote:false,
                deletedFiles:[]
            }
        },
        methods:{
            onError(message){
                this.$emit('error', message);
            },
            onConfirmation(message){
                this.$emit('confirmation', message);
            },
            onDeletedOriginalFile(file){
                this.quotation.original_files = this.quotation.original_files.filter(item => item !== file);
                this.deletedFiles.push(file.id);
            },
            onClientReference(){
                if (this.refTimer) {
                    clearTimeout(this.refTimer);
                    this.refTimer = null;
                }
                if (!this.quotation.client_reference){
                    this.refExists = false;
                }
                else{
                    this.refTimer = setTimeout(this.checkClientReference, this.timerInterval);
                }
            },
            checkClientReference(){
                axios.post(this.checkClientReferenceUrl,
                    {
                        order_id:null,
                        client_reference:this.quotation.client_reference,
                    }
                ).then( ({ data }) => {
                    this.refExists = !!data.refExists;
                }).catch( error => {
                    this.refExists = false;
                    if (error.response.data){
                        if (error.response.data.message)
                            this.$emit('error', error.response.data.message);
                        else
                            this.$emit('error', error.response.data);
                    }
                });
            },
            onPatent(){
                if (this.quotation.patent_bool){
                    this.quotation.claims_bool = true;
                    this.quotation.title_bool = true;
                    this.quotation.description_bool = true;
                    this.quotation.abstract_bool = true;
                    this.quotation.figures_bool = true;
                }
            },
            onChangeDocument(){
                // if (this.quotation.document === 1){
                //     this.quotation.claims_bool = true;
                //     this.quotation.description_bool = true;
                //     this.quotation.abstract_bool = true;
                // }
            },
            removeLanguage(index, e){
                this.quotation.languages.splice(index, 1);
            },
            addLanguage(){
                this.quotation.languages.push(null);
            },
            onPreview(){
                if (this.quotation.id)
                    window.open(this.previewUrl + '?quotation_id=' + this.quotation.id, '_blank');
            },
            onsubmit(e = null) {
                if (e) e.preventDefault();
                let data = Object.assign({sendQuote:this.sendQuote, deletedFiles:this.deletedFiles}, this.quotation);
                data.deadline = moment(data.deadline).utc(true);
                axios({
                    method:this.submit_method,
                    url:this.submit_action,
                    data: data
                }).then( ({data}) => {
                    this.errors = {};
                    this.deletedFiles = [];
                    if (!this.quotation.id){
                        this.quotation.id = data.quotation.id;
                        this.quotation.reference = data.quotation.reference;
                        this.submit_action = data.action;
                        this.submit_method = data.method;
                        history.replaceState({quotation:data.quotation}, '', data.url);
                    }

                    this.$emit('confirmation', data.message);

                }).catch( error => {
                    if (error.response && error.response.data){
                        if (error.response.data.errors){
                            this.errors = error.response.data.errors;
                        }
                        if (error.response.data.message)
                            this.$emit('error', error.response.data.message);
                    }

                });
            },
            SaveAndSend(e){
                if (confirm(__('Send quote?'))){
                    this.sendQuote = true;
                    this.onsubmit(e);
                    this.sendQuote = false;
                }

            }
        },
        props:{
            method:String,
            url:String,
            previewUrl:String,
            languagesUrl:String,
            settingsUrl:String,
            fieldsUrl:String,
            checkClientReferenceUrl:String,
            clientsUrl:String,
            uploadUrl:String,
            action:String,
            id:Number,
            status:Object,
            documents:Object,
            timerInterval:{
                type:Number,
                default:300
            }
        },
        watch:{
            'quotation.client_id'(newVal, oldVal){
                this.selectedClient = this.clients.find(item => item.id === newVal);
                if (this.selectedClient){
                    this.contacts = this.selectedClient.contacts;
                    if (this.quotation.contact_id){
                        let contact = this.contacts.find(item => item.id === this.quotation.contact_id);
                        if (!contact) this.quotation.contact_id = null;
                    }
                }
                else
                    this.quotation.contact_id = null;
            },
            'quotation.contact_id'(newVal, oldVal){
                let contact = this.contacts.find(item => item.id === newVal);
                this.quotation.emails = contact ? contact.email : null;
            }
        }
    }
</script>

<style scoped>

</style>
