<template>
<b-alert :variant="variant" :show="dismissCountDown" dismissible fade>
    <slot></slot>
</b-alert>
</template>

<script>
    export default {
        name: "GlobalMessage",
        data(){
            return {
                dismissCountDown: 3
            }
        },
        props:{
            variant:{
                type:String
            }
        }
    }
</script>

<style scoped>
    .alert{
        margin-bottom:0;
    }
</style>
