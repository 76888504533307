<template>
<div>
    <div class="form-group row">
        <label :for="name+'_file'" class="col-md-4 col-form-label text-left text-md-right">{{ _.capitalize(__(label)) }} <span v-if="required">*</span></label>
        <div class="col-md-8">
            <input type="file" :id="name+'_file'" :name="name+'_file'" class="form-control-file" :class="{'is-invalid':errors[name+'_files']}" ref="file" @change="setFile">
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" :class="{'bg-success':progressValue>=100}" :style="{'width':progressValueLabel}">{{ progressValueLabel }}</div>
            </div>
            <div class="invalid-feedback" v-if="errors[name+'_files']" v-for="error in errors[name+'_files']">{{ error }}</div>
        </div>
    </div>
    <div class="form-group row">
        <label :for="name+'_name'" class="col-md-4 col-form-label text-left text-md-right">{{ _.capitalize(__(desc)) }}</label>
        <div class="col-md-8">
            <input type="text" :id="name+'_name'" :name="name+'_name'" v-model="fileName" class="form-control" @keydown.enter.prevent="addFile" :placeholder="descPlaceholder">
        </div>
    </div>
    <div class="form-group text-left text-md-right">
        <button type="button" class="btn btn-primary" @click="addFile">{{ __('Add a file') }}</button>
    </div>

    <div class="row" v-for="v in value">
        <div class="d-flex justify-content-between col-md-8 offset-md-4">
            <a :href="v.url" target="_blank">{{ v.name || v.filename }}</a>
            <button type="button" class="btn btn-link" @click="removeFile(v)"><font-awesome-icon icon="trash"></font-awesome-icon></button>
        </div>

    </div>


</div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "DocumentItem",
        data(){
            return {
                fileName:null,
                file:null,
                files:[],
                progressValue:0,
            }
        },
        props:{
            name:String,
            label:String,
            desc:{
                type:String,
                default:'File name'
            },
            descPlaceholder:String,
            uploadUrl:String,
            type:Number,
            errors:{
                type:Object,
                default:function(){
                    return {};
                }
            },
            required:{
                type:Boolean,
                default:false
            },
            value:{
                type:Array,
                required:true
            },
        },
        computed:{
            progressValueLabel(){
                return this.progressValue > 0 ? this.progressValue + '%' : null;
            }
        },
        methods:{
            setFile(){
                this.file = this.$refs.file.files[0];
            },
            addFile(){
                let fData = new FormData();
                fData.append('file', this.file);
                if (this.fileName) fData.append('name', this.fileName);
                fData.append('type', this.type);

                axios.post(this.uploadUrl, fData, {
                    headers:{
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress:function(e){
                        this.progressValue = parseInt( Math.round( ( e.loaded * 100 ) / e.total ) );
                    }.bind(this)
                }).then(({data}) => {
                    this.value.push(data);
                    this.file = null;
                    this.$refs.file.value = null;
                    this.fileName = null;
                    this.progressValue = 0;

                }).catch(error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$emit('error', error.response.data.message);
                });
            },
            removeFile(file, e){
                if (confirm(__('Remove the document ?'))){
                    if (file.type === 1)
                        this.$emit('deletedOriginalFile', file);
                    else if (file.type === 2)
                    this.$emit('deletedTranslationFile', file);
                }
            }
        }
    }
</script>

<style scoped>

</style>
