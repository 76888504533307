<template>
    <div class="loading-overlay" :hidden="hidden"></div>
</template>

<script>
    export default {
        name: "LoadingOverlay",
        props:{
            hidden:{
                type:Boolean,
                default:true
            }
        }
    }
</script>

<style scoped>
    .loading-overlay{
        cursor: wait;
        background: rgba(0, 0, 0, 0.4);
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: 10000;
    }
</style>