<template>
<div>
    <base-datatable
            @updated="onDatatableUpdated"
            @filtered="onDatatableFiltered"
            ref="table"
            :filters="filters"
            :url="url"
            :can-edit="canEdit"
            :can-delete="canDelete"
            init-dir="desc"
            v-on:error="$root.onError"
            v-on:confirmation="$root.onConfirmation"
    >
        <template #filters>
            <select v-model="month" class="form-control mr-sm-3 mb-2 mb-sm-0">
                <option :value="null">{{ __('Month') }}</option>
                <option v-for="(m, i) in monthsList" :value="i+1">{{ m }}</option>
            </select>

            <select v-model="year" class="form-control mr-sm-3 mb-2 mb-sm-0">
                <option :value="null">{{ __('Year') }}</option>
                <option v-for="y in yearsList" :value="y">{{ y }}</option>
            </select>

            <select v-model.number="selectedStatus" class="form-control">
                <option :value="null">{{ __('All')}}</option>
                <option v-for="(s, i) in status" :value.number="i">{{ s }}</option>
            </select>
        </template>

        <template #col>
            <th>{{ __('Invoice') }}</th>
            <th><input type="checkbox" v-model="toggleAll"></th>
        </template>

        <template #extra="slotProps">
            <td><a :href="slotProps.item.invoice_url" class="btn btn-link" target="_blank"><font-awesome-icon size="lg" icon="file-pdf"></font-awesome-icon></a></td>
            <td><input type="checkbox" :value="slotProps.item" v-model="selection"></td>
        </template>

        <template #bottom>
            <div class="m-3 text-right">
                <button class="btn btn-primary mr-3" @click="exportSelection">{{ __('Excel export') }}</button>
                <font-awesome-icon icon="level-down-alt" rotation="90" size="lg" fixed-width></font-awesome-icon>
            </div>
        </template>
    </base-datatable>


</div>
</template>

<script>

    export default {
        mounted() {
            this.monthsList = moment.months();
        },
        data(){
            return {
                month:null,
                year:null,
                monthsList:[],
                yearsList:[],
                selectedStatus:null,
                selectedOrder:null,
                toggleAll:false,
                selection:[],
            }
        },
        props: {
            status:Object,
            canDelete:{
                type:Boolean,
                default:false
            },
            canEdit:{
                type:Boolean,
                default:false
            },
            url:{
                type:String,
                required:true
            },
            exportUrl:{
                type:String,
                required:true
            }
        },
        methods:{
            onDatatableUpdated(){
                let min = _.minBy(this.$refs.table.$data.originalItems, 'creation_date');
                let minYear = min.year;

                let now = moment();
                let year = now.year();
                for(let y = minYear; y <= year ; y++)
                    this.yearsList.push(y);
            },
            onDatatableFiltered(){
                this.toggleAll = false;
            },
            displayTranslations(order){
                this.selectedOrder = order;
                this.$bvModal.show('translations_modal');
            },
            exportSelection(){
                window.open(this.exportUrl + '?' + this.selection.map(item => 'selection[]=' + item.id).join('&'), '_blank');
            },
        },
        computed:{
            filters:function(){
                let f = {
                    month:this.month,
                    year:this.year
                };
                if (this.selectedStatus !== null) f.status = this.selectedStatus;
                return f;
            }
        },
        watch:{
            toggleAll(newVal, oldVal){
                this.selection = newVal ? this.$refs.table.$data.filteredItems : [];
            }
        }
    }
</script>

<style scoped>

</style>