<template>
<div>
    <div class="row">
        <div class="col-md-8 form-inline my-3">
            <slot name="filters"></slot>
        </div>

        <div class="col-md-4 form-inline my-3 justify-content-end">
            <slot name="search">
                <label for="search-input" class="mr-2">{{ __('Search') }}</label>
                <input id="search-input" v-model="search" class="form-control">
            </slot>
        </div>
    </div>

    <table class="table-list table table-bordered">
        <thead>
        <tr>
            <th v-for="col in cols" :class="{active:col.name === sort.name, sortable:col.sortable}" @click="orderBy(col)">
                <span class="text-nowrap">{{ col.label}}</span>
                <font-awesome-icon v-if="col.sortable" :icon="col.name === sort.name && dir === 'desc' ? 'sort-amount-down' : 'sort-amount-down-alt'" class="float-right"></font-awesome-icon>
            </th>
            <slot name="col"></slot>
            <th class="cell-action" v-if="canEdit"></th>
            <th class="cell-action" v-if="canDelete"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in items">
            <td v-for="col in cols">
                <template v-if="Array.isArray(item[col.name])">
                <span v-for="v in item[col.name]" v-html="v" class="d-block text-truncate" :title="v"></span>
                </template>
                <span v-else v-html="item[col.name]"></span>
            </td>
            <slot :name="'extra'" :item="item"></slot>
            <td v-if="canEdit && item.edit_url"><a :href="item.edit_url" class="btn btn-link p-0"><font-awesome-icon icon="edit"></font-awesome-icon></a></td>
            <td v-if="canDelete && item.destroy_url"><button @click="destroy(item)" class="btn btn-link p-0"><font-awesome-icon icon="trash-alt"></font-awesome-icon></button></td>
        </tr>
        </tbody>
    </table>

    <slot name="bottom"></slot>

    <nav>
        <ul class="pagination justify-content-end flex-wrap">
            <li class="page-item" :class="{disabled:page <= 1}"><a href="" class="page-link" @click.prevent="gotoPage(page - 1)">{{ __('Previous') }}</a></li>
            <template  v-for="i in maxPage">
                <li class="page-item" v-if="i > 1 && i === page - 5"><span class="page-link">...</span></li>
                <li class="page-item" :class="{active:i === page}" v-if="i === 1 || (i > page - 5 && i < page + 5) || i === maxPage"><a href="#" class="page-link" @click.prevent="gotoPage(i)">{{ i }}</a></li>
                <li class="page-item" v-if="i < maxPage && i === page + 5"><span class="page-link">...</span></li>
            </template>
            <li class="page-item" :class="{disabled:page >= maxPage}"><a href="" class="page-link" @click.prevent="gotoPage(page + 1)">{{ __('Next') }}</a></li>
        </ul>
    </nav>
</div>
</template>

<script>
    import axios from 'axios';


    export default {
        mounted() {
            this.refresh();
        },

        data(){
            return {
                search:'',
                originalItems:[],
                filteredItems:[],
                page:1,
                sort:null,
                dir:null,
                cols:[],
                items:[]
            }
        },

        props: {
            initCb:Function,
            initSort:String,
            initDir:String,
            filters:{
                type:Object,
                default:function(){
                    return {};
                }
            },
            searchCols:{
                type:Array,
                default:function(){
                    return [];
                }
            },
            itemsPerPage:{
                type:Number,
                default:10
            },
            canDelete:{
                type:Boolean,
                default:false
            },
            canEdit:{
                type:Boolean,
                default:false
            },
            url:{
                type:String,
                required:true
            }
        },

        computed:{
            maxPage(){
                return Math.ceil(this.filteredItems.length / this.itemsPerPage);
            }
        },

        methods: {
            refresh(){
                axios.get(this.url).then( response => {
                    if (this.initCb) this.initCb(response.data);
                    this.cols = response.data.cols;
                    this.originalItems = this.filteredItems = response.data.items;
                    if (!this.sort)
                        this.sort = this.initSort ? this.cols.find(item => item.name === this.initSort) : this.cols[0];
                    let dir;
                    if (!this.dir)
                        dir = this.initDir ? this.initDir : 'asc';
                    else
                        dir = this.dir;

                    this.orderBy(this.sort, dir);
                    this.$emit('updated');
                });
            },
            gotoPage(i){
                if (i < 1 || i > this.maxPage)
                    return;

                this.page = i;
                this.filterByPage(i);
            },
            filterByPage(p){
                this.items = this.filteredItems.filter((item, index) => index >= this.itemsPerPage * (p - 1) && index < this.itemsPerPage * p);
            },
            orderBy(col, dir){
                if (!col.sortable)
                    return;

                if (this.sort.name === col.name){
                    this.dir = dir ? dir : (this.dir === 'asc' ? 'desc' : 'asc');
                }
                else{
                    this.sort = col;
                    this.dir = dir ? dir : 'asc';
                }

                let dateFormat = 'DD-MM-YYYY';
                this.filteredItems = this.filteredItems.sort((a, b) => {
                    let s1, s2;

                    if (this.dir === 'asc'){
                        s1 = '' + (a[this.sort.name] === null ? '' : a[this.sort.name]);
                        s2 = '' + (b[this.sort.name] === null ? '' : b[this.sort.name]);
                    }
                    else {
                        s1 = '' + (b[this.sort.name] === null ? '' : b[this.sort.name]);
                        s2 = '' + (a[this.sort.name] === null ? '' : a[this.sort.name]);
                    }
                    let options = { sensitivity: 'base' };
                    if (col.numeric) options.numeric = true;
                    if (col.date){
                        s1 = moment(s1, dateFormat);
                        s2 = moment(s2, dateFormat);
                        if (s1.isSame(s2)) return 0;
                        else return s1.isAfter(s2) ? 1 : -1;
                    }
                    return s1.localeCompare(s2, 'en', options);
                });

                this.filterByPage(this.page);
            },
            destroy(item) {
                if (item.destroy_url && confirm('Delete ?')){
                    axios.delete(item.destroy_url).then( ({data}) => {
                        this.originalItems = this.items = this.items.filter(item2 => item2.id !== item.id);
                        this.$emit('confirmation', data);
                    }).catch( error => {
                        if (error.response.data)
                            this.$emit('error', error.response.data);
                    });
                }
            },
            filter(){
                let search = this.search.toLowerCase();
                this.filteredItems = this.originalItems.filter(item => {
                    let ok = true, haystack;
                    for(let [key, value] of Object.entries(this.filters)){
                        if (typeof value === 'function'){
                          if (!value(item[key]))
                            ok = false;
                        }
                        else if (value !== null){
                            haystack = !Array.isArray(item[key]) ? [item[key]] : item[key];
                            if (haystack.findIndex(item2 => _.isEqual(item2, value)) === -1)
                                ok = false;
                        }
                    }
                    return ok && (this.cols.some(col => {
                        return ('' + item[col.name]).toLowerCase().indexOf(search) !== -1;
                    }) || this.searchCols.some(col => {
                        return ('' + item[col]).toLowerCase().indexOf(search) !== -1;
                    }));
                });
                if (this.page > this.maxPage) this.page = Math.max(this.maxPage, 1);
                this.orderBy(this.sort, this.dir);
                this.$emit('filtered');
            }
        },
        watch:{
            filters:{
                handler(newVal, oldVal){
                    this.filter();
                },
                deep:true
            },
            search(newVal, oldVal){
                this.filter();
            }
        }

    }
</script>

<style scoped>

</style>
